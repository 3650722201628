<template>
    <div class="col-12 col-md-4 offset-md-4 mt-2">
        <input
            type="text"
            placeholder="search"
            class="form-control rounded-pill mb-3"
            v-model="searchvalue"
        />
    </div>

    <EasyDataTable
        :headers="headers"
        :items="appsDataTable"
        :search-value="searchvalue"
        :sort-by="'date_applied'"
        :sort-type="'desc'"
        header-class-name="datatable-header"
        alternating
    >
        <template #header-status="headers">
            <div class="filter-column">
                <div
                    role="button"
                    @click.stop="showStatusFilter = !showStatusFilter"
                >
                    <i class="bi bi-filter me-2"></i>
                    {{ headers.text }}
                </div>
                <div class="filter-menu" v-if="showStatusFilter">
                    <select
                        class="form-select statusFilter-selector"
                        v-model="statusFilter"
                        name="statusFilter"
                        @change="filterByStatus()"
                    >
                        <option value="">all</option>
                        <option
                            v-for="optStat in statusFilterOptions"
                            :value="optStat"
                            :key="optStat"
                        >
                            {{ optStat }}
                        </option>
                    </select>
                </div>
            </div>
        </template>

        <template #item-date_applied="appD1">
            {{ formatDateLocal(appD1.date_applied) }}
        </template>
        <template #item-operation="appD">
            <div class="d-flex">
                <div>
                    <router-link
                        class="text-decoration-none primaryColor fw-bold"
                        href="#"
                        :to="{
                            name: 'ViewAnApplication',
                            params: {
                                id: appD.full_app._id,
                                user_id: appD.full_app.user_id._id,
                            },
                        }"
                        ><i class="bi bi-arrow-up-right me-1"></i><br />View
                        Application</router-link
                    >
                </div>
                <div
                    v-if="!appD.full_app.application_complete && canAssign"
                    class="ms-3"
                >
                    <a
                        href="#"
                        class="text-decoration-none primaryColor fw-bold"
                        @click.prevent="
                            $emit('showAssignModal', appD.full_app._id)
                        "
                        data-bs-toggle="modal"
                        data-bs-target="#assignModal"
                        ><i class="bi bi-person me-1"></i><br />{{ appD.full_app.assigned_admin == null ? 'Assign' : 'Re-assign'}} Admin</a
                    >
                </div>
            </div>
        </template>
        <template #item-application_fee="pay">
            {{ pay.full_app.payment?.currency_symbol
            }}{{ pay.full_app.payment?.amount_paid }}
        </template>
        <template #item-tracker="appD">
            <div class="text-center text-danger mb-2">
                {{ calcTracker(appD.full_app) }}
            </div>
            <div class="stepProgressBar">
                <div
                    v-for="(trk, index) in tracks"
                    :key="trk.progress"
                    class="step d-flex flex-column"
                    :class="{ last: tracks?.length == index + 1 }"
                >
                    <div class="step-text text-start">{{ trk.status }}</div>
                    <div
                        class="bullet"
                        :class="{
                            completed:
                                tracks.find(
                                    (x) =>
                                        x.status === calcTracker(appD.full_app)
                                )?.progress >=
                                index + 1,
                            last: tracks?.length == index + 1,
                        }"
                    >
                        <i
                            class="bi bi-check-lg"
                            v-if="
                                tracks.find(
                                    (x) =>
                                        x.status === calcTracker(appD.full_app)
                                )?.progress >=
                                index + 1
                            "
                        ></i>
                    </div>
                    <div
                        v-if="
                            tracks.find(
                                (x) => x.status === calcTracker(appD.full_app)
                            )?.progress >=
                            index + 1
                        "
                        class="middle-line"
                    ></div>
                </div>
            </div>
        </template>
        <template #item-last_note="app">
            <div v-if="app.last_note">
                <div v-if="app.last_note.note.length > numberOfCharacters">
                    {{
                        `${app.last_note.note.slice(0, numberOfCharacters)} ...`
                    }}
                </div>
                <div v-else>
                    {{ app.last_note.note }}
                </div>

                <div class="mt-1 small">
                    {{
                        app.last_note.created_by?.first_name +
                        ' ' +
                        app.last_note.created_by?.last_name
                    }}
                    <div class="small">
                        {{ formatDateLocal(app.last_note.created_on) }}
                    </div>
                </div>
            </div>
        </template>
    </EasyDataTable>
</template>
<script>
import { formatDate } from '@/utils/format';
import { calcTracker } from '@/utils/constants';
import { applicationTracker } from '@/utils/constants';
export default {
    name: 'ApplicationTable',
    props: ['appsPass', 'canAssignPass'],
    emits: ['showAssignModal'],
    data: function () {
        return {
            canAssign: false,
            tracks: [],
            apps: [],
            showStatusFilter: false,
            statusFilter: '',
            statusFilterOptions: [],
            appsDataTable: [],
            searchvalue: '',
            headers: [
                { text: 'Scout Name', value: 'scout_name', sortable: true },
                { text: 'Student Name', value: 'student_name', sortable: true },
                {
                    text: 'School',
                    value: 'school_name',
                    sortable: true,
                    width: 150,
                },
                {
                    text: 'Program',
                    value: 'program_name',
                    sortable: true,
                    width: 250,
                },
                {
                    text: 'Application Fee',
                    value: 'application_fee',
                    sortable: true,
                },
                {
                    text: 'Application ID',
                    value: 'application_id',
                    sortable: true,
                },
                { text: 'Status', value: 'status', width: 150 },
                {
                    text: 'Assigned Admin',
                    value: 'assigned_admin',
                    sortable: true,
                },
                { text: 'Date Applied', value: 'date_applied', sortable: true },
                { text: '', value: 'operation' },
                { text: 'Application Tracker', value: 'tracker' },
                { text: 'Latest Admin Note', value: 'last_note', width: 300 },
            ],
            numberOfCharacters: 100,
        };
    },
    methods: {
        formatDateLocal(dt) {
            return formatDate(dt);
        },
        calcTracker(app) {
            return calcTracker(app);
        },
        filterByStatus() {
            if (this.statusFilter == '') {
                this.formatDataTable(this.apps);
                return;
            }
            let ap = this.apps.filter((x) => x.status == this.statusFilter);
            this.formatDataTable(ap);
        },
        formatDataTable(apps) {
            this.appsDataTable = apps.map((x) => ({
                application_fee: `${x.payment?.amount_paid}`,
                scout_name:
                    x.scout_id != null
                        ? `${x.scout_id?.first_name} ${x.scout_id?.last_name}`
                        : '',
                assigned_admin:
                    x.assigned_admin != null
                        ? `${x.assigned_admin?.first_name} ${x.assigned_admin?.last_name}`
                        : '',
                student_name: `${x.user_id?.personal_info?.first_name} ${x.user_id?.personal_info?.last_name}`,
                school_name: x.university.name,
                program_name: x.program.name,
                application_id: x._id,
                status: x.status,
                date_applied:
                    x.submitted_on != null ? x.submitted_on :
                    x.payment?.date_of_payment != undefined
                        ? x.payment?.date_of_payment
                        : x.updated_on != undefined
                        ? x.updated_on
                        : x.created_on,
                full_app: x,
                last_note: x.notes?.length > 0 ? x.notes[x.notes.length - 1] : null,
            }));
        },
    },
    mounted() {
        this.tracks = applicationTracker;
        this.canAssign = this.canAssignPass;
        this.apps = this.appsPass;
        this.statusFilterOptions = [...new Set(this.apps.map((x) => x.status))];
        this.formatDataTable(this.apps);
    },
};
</script>
