import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/HomePage.vue';
import GuestHome from '../views/GuestHome.vue';
import GuestFindProgram from '../views/FindProgram.vue';
import SchoolList from '@/components/Schools.vue';
import ProgramList from '@/components/Programs.vue';
import StudentSchool from '../views/School.vue';
import StudentProgram from '../views/Program.vue';
import store from '@/store/index';
import studentRoute from './studentRoute';
import adminRoute from './adminRoute';
import scoutRoute from './scoutRoute';

const routes = [
    {
        path: '/:catchAll(.*)',
        redirect: { name: 'GuestHomePage' },
    },
    {
        path: '/guest/',
        name: 'Home',
        component: GuestHome,
        children: [
            {
                path: '/',
                redirect: { name: 'GuestHomePage' },
            },
            {
                path: 'home',
                name: 'GuestHomePage',
                component: Home,
            },
            {
                path: 'about',
                name: 'About',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "about" */ '../views/AboutPage.vue'
                    ),
            },
            {
                path: 'find-programs/',
                name: 'GuestFindPrograms',
                component: GuestFindProgram,
                children: [
                    {
                        path: '',
                        redirect: { name: 'GuestProgramList' },
                    },
                    {
                        name: 'GuestSchoolList',
                        path: 'schools',
                        component: SchoolList,
                        beforeEnter: (to, from, next) => {
                            const userState = store.state;
                            if (userState.user.currentUser != null) {
                                next({ name: 'SchoolList' });
                            } else if (userState.admin.currentUser != null) {
                                next({ name: 'AdminSchoolList' });
                            } else if (userState.scout.currentUser != null) {
                                next({ name: 'ScoutSchoolList' });
                            }
                            else {
                                next();
                            }
                        },
                    },
                    {
                        name: 'GuestProgramList',
                        path: 'programs',
                        component: ProgramList,
                        beforeEnter: (to, from, next) => {
                            const userState = store.state;
                            if (userState.user.currentUser != null) {
                                next({ name: 'ProgramList' });
                            } else if (userState.admin.currentUser != null) {
                                next({ name: 'AdminProgramList' });
                            } else if (userState.scout.currentUser != null) {
                                next({ name: 'ScoutProgramList' });
                            } 
                            else {
                                next();
                            }
                        },
                    },
                ],
            },
            {
                path: 'school/:id',
                name: 'SchoolPage',
                component: StudentSchool,
                beforeEnter: (to, from, next) => {
                    const userState = store.state;
                    if (userState.user.currentUser != null) {
                        next({
                            name: 'StudentSchool',
                            params: { id: to.params.id },
                            query: to.query,
                        });
                    } else if (userState.admin.currentUser != null) {
                        next({
                            name: 'AdminSchool',
                            params: { id: to.params.id },
                            query: to.query,
                        });
                    } else if (userState.scout.currentUser != null) {
                        next({
                            name: 'ScoutSchool',
                            params: { id: to.params.id },
                            query: to.query,
                        });
                    } 
                    else {
                        next();
                    }
                },
            },
            {
                path: 'school/:school_id/program/:id',
                name: 'ProgramPage',
                component: StudentProgram,
                beforeEnter: (to, from, next) => {
                    const userState = store.state;
                    if (userState.user.currentUser != null) {
                        next({
                            name: 'StudentProgram',
                            params: {
                                school_id: to.params.school_id,
                                id: to.params.id,
                            },
                            query: to.query,
                        });
                    } else if (userState.admin.currentUser != null) {
                        next({
                            name: 'AdminProgram',
                            params: {
                                school_id: to.params.school_id,
                                id: to.params.id,
                            },
                            query: to.query,
                        });
                    } else if (userState.scout.currentUser != null) {
                        next({
                            name: 'ScoutProgram',
                            params: {
                                school_id: to.params.school_id,
                                id: to.params.id,
                            },
                            query: to.query,
                        });
                    } 
                    else {
                        next();
                    }
                },
            },
            {
                path: 'school/:school_id/program/:program_id/apply/:id',
                name: 'GuestApplyPage',
                component: StudentProgram,
                beforeEnter: (to, from, next) => {
                    const userState = store.state;
                    if (userState.scout.currentUser != null) {
                        next({
                            name: 'ScoutApplyForStudent',
                            params: {
                                school_id: to.params.school_id,
                                program_id: to.params.program_id,
                                id: to.params.id,
                            },
                            query: to.query,
                        });
                    } else if (userState.admin.currentUser != null) {
                        next({
                            name: 'AdminViewApplyForm',
                            params: {
                                school_id: to.params.school_id,
                                program_id: to.params.program_id,
                                id: to.params.id,
                            },
                            query: to.query,
                        });
                    }
                    else {
                        next({
                            name: 'StudentApplyForm',
                            params: {
                                school_id: to.params.school_id,
                                program_id: to.params.program_id,
                                id: to.params.id,
                            },
                            query: to.query,
                        });
                    }
                },
            },
        ],
    },
    studentRoute,
    adminRoute,
    scoutRoute
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.path.indexOf('/students/') > -1) {
        const user = store.state.user.currentUser;
        if (
            to.meta.requiresAuth === true &&
            (user == null || user.uuser.role !== 'student')
        ) {
            next({
                path: `/students/login`,
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } 
    else if (to.path.indexOf('/scout/') > -1) {
        const user = store.state.scout.currentUser;
        if (
            to.meta.requiresAuth === true &&
            (user == null || user.uuser.role !== 'scout')
        ) {
            next({
                path: `/scout/login`,
                query: { redirect: to.fullPath },
            });
        } else if(to.meta.requiresAuth === true && !user.uuser.terms_conditions_accepted && to.name != "ScoutsTermsAndConditions"){
            next({
                path: '/scout/terms-and-conditions',
                query: { redirect: to.fullPath },
            });
        }
        else {
            next();
        }
    }
    else if (to.path.indexOf('/admin/') > -1) {
        const user = store.state.admin.currentUser;
        if (
            to.meta.requiresAuth === true &&
            user == null &&
            user?.uuser?.role !== 'admin' &&
            user?.uuser?.role !== 'intermediate-admin'
        ) {
            next({
                path: `/admin/login`,
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

router.afterEach(() => {
    window.gtag('event', 'page_view', {
        page_title: window.location.href,
        page_location: window.location.href,
        page_path: window.location.host,
        send_to: 'G-VDBQJDVJMC',
    });
});

export default router;
